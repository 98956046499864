'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.CommentPopupComponent = function CommentPopupComponent() {
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    var commentTriggers = document.querySelectorAll('[data-open-comment]');

    if (commentTriggers.length) {
      var overlay = document.querySelector('.finalists-comments-overlay');

      commentTriggers.forEach(function (trigger) {
        trigger.addEventListener('click', function (e) {
          e.preventDefault();

          var commentId = trigger.getAttribute('data-open-comment');
          var comment = document.querySelector('[data-popup="' + commentId + '"]');

          overlay.classList.add('is-active');
          comment.classList.add('is-active');
        });
      });

      overlay.addEventListener('click', function (e) {
        e.preventDefault();

        overlay.classList.remove('is-active');
        commentTriggers.forEach(function (trigger) {
          var commentId = trigger.getAttribute('data-open-comment');
          var comment = document.querySelector('[data-popup="' + commentId + '"]');

          comment.classList.remove('is-active');
        });
      });

      var closeButtons = document.querySelectorAll('.popup-close');
      closeButtons.forEach(function (button) {
        button.addEventListener('click', function (e) {
          overlay.dispatchEvent(new Event('click'));
        });
      });
    }

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();