'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.FormDelete = function FormDelete() {
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    var deleteTrigger = document.querySelector('[data-delete-application]');
    if (deleteTrigger) {
      deleteTrigger.addEventListener('click', function (e) {
        e.preventDefault();
        var deletionForm = document.querySelector('.deletion-form');
        if (deletionForm) {
          deletionForm.submit();
        }
      });
    }

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();