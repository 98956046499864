'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.SelectSchoolComponent = function SelectSchoolComponent() {
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    var centreSelect = document.querySelector('[data-select-centre]');
    if (centreSelect) {
      var schoolSelect = document.querySelector('[data-select-school]');
      if (schoolSelect) {
        var dataset = JSON.parse(centreSelect.dataset.selectCentre);

        centreSelect.addEventListener('change', function (e) {
          var centreId = e.target.value;
          var schools = dataset.filter(function (school) {
            return school.centreId === parseInt(centreId, 10);
          });

          var defaultOption = schoolSelect.querySelector('option').outerHTML;
          var schoolsOptions = defaultOption + schools.map(function (school) {
            return '<option value="' + school.id + '">' + school.title + '</option>';
          }).join('');

          schoolSelect.innerHTML = schoolsOptions;

          var refreshOptions = schoolSelect.querySelectorAll('option');
          if (refreshOptions.length === 2) {
            refreshOptions[1].selected = true;
          }
        });
      }
    }

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();