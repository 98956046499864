'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Tables = function Tables() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  var $tablesRows = $('[data-cat-clickable]');

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $tablesRows.on('click', function (e) {
      if ($(this).data('cat-url') != null) {
        window.location = $(this).data('cat-url');
      } else {
        $($(this).data('cat-form')).submit();
      }
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();