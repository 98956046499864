'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Modals = function Modals() {

    'use strict';

    /**
     * jQuery elements
     * @private
     */

    /**
     * Has the class been initialized?
     * @private
     */

    var inited = false;

    /**
     * Initializes the class.
     * @public
     */
    var init = function init() {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        var $modalTriggers = document.querySelectorAll('[data-modal-trigger]');
        var $modals = document.querySelectorAll('.modal');

        $modalTriggers.forEach(function ($modalTrigger) {
            $modalTrigger.addEventListener('click', function (e) {
                e.preventDefault();
                var moddalType = $modalTrigger.dataset.modalTrigger;
                var modal = document.getElementById(moddalType);

                if (modal) {
                    open(modal);
                }
            });
        });

        $modals.forEach(function ($modal) {
            $modal.addEventListener('click', function (e) {
                if (e.target.classList.contains('modal')) {
                    close($modal);
                }
            });

            var $closeButton = $modal.querySelector('.modal-close');

            if ($closeButton) {
                $closeButton.addEventListener('click', function (e) {
                    e.preventDefault();
                    close($modal);
                });
            }
        });

        return true;
    };

    var open = function open(modal) {
        if (modal) {
            modal.classList.add('is-opened');
            document.body.classList.add('is-locked');
        }
    };

    var close = function close(modal) {
        if (modal) {
            modal.classList.remove('is-opened');
            document.body.classList.remove('is-locked');
        }
    };

    // Expose public methods & properties
    return {
        init: init
    };
}();