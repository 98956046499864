'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.SelectsTop3 = function SelectsTop3() {

    'use strict';

    /**
     * jQuery elements
     * @private
     */

    /**
     * Has the class been initialized?
     * @private
     */

    var inited = false;

    /**
     * Initializes the class.
     * @public
     */
    var init = function init() {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        var $selects = document.querySelectorAll('.select-top');
        $selects.forEach(function ($currentSelect) {
            $currentSelect.addEventListener('change', function (e) {
                var $selectsValues = [];
                $selects.forEach(function ($select) {
                    if ($select.value !== '') {
                        $selectsValues.push($select.value);
                    }
                });

                $selects.forEach(function ($select) {
                    if ($select !== $currentSelect) {
                        var $options = $select.querySelectorAll('option');

                        $options.forEach(function ($option) {
                            if ($option.value !== '') {
                                if ($selectsValues.includes($option.value) && !$option.selected) {
                                    $option.disabled = true;
                                } else {
                                    $option.disabled = false;
                                }
                            }
                        });
                    }
                });
            });
        });

        return true;
    };

    // Expose public methods & properties
    return {
        init: init
    };
}();