'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.NotationComponent = function NotationComponent() {
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    var notationComponents = document.querySelectorAll('.notation-component');
    if (notationComponents.length) {
      notationComponents.forEach(function (notationComponent) {
        var minusButton = notationComponent.querySelector('.button-minus');
        var plusButton = notationComponent.querySelector('.button-plus');
        var input = notationComponent.querySelector('.notation-input');
        var maxNotation = parseInt(notationComponent.getAttribute('data-max'), 10);

        minusButton.addEventListener('click', function () {
          if (input.value > 0) {
            input.value = parseInt(input.value, 10) - 1;
          }
        });

        plusButton.addEventListener('click', function () {
          if (parseInt(input.value, 10) < maxNotation) {
            input.value = parseInt(input.value, 10) + 1;
          }
        });

        input.addEventListener('focus', function () {
          input.select();
        });

        input.addEventListener('change', function () {
          var value = parseInt(input.value, 10);
          if (value < 0) {
            input.value = 0;
          } else if (value > maxNotation) {
            input.value = maxNotation;
          }
        });
      });
    }

    var voteInputs = document.querySelectorAll('.vote-label > input');
    voteInputs.forEach(function (input) {
      input.addEventListener('change', function () {
        var form = input.closest('form');
        form.submit();
      });
    });

    var removeWinnerTriggers = document.querySelectorAll('[data-remove-winner]');
    if (removeWinnerTriggers.length) {
      removeWinnerTriggers.forEach(function (removeWinnerTrigger) {
        removeWinnerTrigger.addEventListener('click', function (e) {
          e.preventDefault();
          // Confirm action
          if (!window.confirm('Êtes-vous sûr de vouloir retirer la notion de gagnant ?')) {
            return;
          }
          var form = removeWinnerTrigger.closest('form');
          var formData = new FormData(form);
          var formAction = form.querySelector('[name="action"]').value;

          axios.post(formAction, formData).then(function (response) {
            if (response.data) {
              var categorySection = removeWinnerTrigger.closest('.finalists-category-section');
              var finalistsSelection = categorySection.querySelector('.js-finalists-selection');

              if (finalistsSelection) {
                finalistsSelection.disabled = false;
              }

              removeWinnerTrigger.classList.remove('is-winner');
              removeWinnerTrigger.classList.remove('is-active');

              var cantBeWinnerTriggers = categorySection.querySelectorAll('[data-winner-state="cant-be-winner"]');
              if (cantBeWinnerTriggers.length) {
                cantBeWinnerTriggers.forEach(function (cantBeWinnerTrigger) {
                  cantBeWinnerTrigger.classList.remove('is-active');
                });
              }

              var canBeWinnerTriggers = categorySection.querySelectorAll('[data-winner-state="can-be-winner"]');
              if (canBeWinnerTriggers.length) {
                canBeWinnerTriggers.forEach(function (canBeWinnerTrigger) {
                  canBeWinnerTrigger.classList.add('is-active');

                  var triggerForm = canBeWinnerTrigger.closest('form');
                  var inputSelectVictory = triggerForm.querySelector('[name="fields[selectVictory]"]');
                  if (inputSelectVictory) {
                    inputSelectVictory.value = 1;
                  }
                  var inputProvincialSubmitted = triggerForm.querySelector('[name="fields[provincialSubmitted]"]');
                  if (inputProvincialSubmitted) {
                    inputProvincialSubmitted.value = 1;
                  }
                });
              }
            }
          }).catch(function (error) {
            console.log(error);
          });
        });
      });
    }

    var winnerTriggers = document.querySelectorAll('[data-winner]');
    if (winnerTriggers.length) {
      winnerTriggers.forEach(function (winnerTrigger) {
        winnerTrigger.addEventListener('click', function (e) {
          e.preventDefault();
          // Confirm action
          if (!window.confirm('Êtes-vous sûr de vouloir définir la candidature comme gagnant ?')) {
            return;
          }
          var form = winnerTrigger.closest('form');
          var formData = new FormData(form);
          var formAction = form.querySelector('[name="action"]').value;

          axios.post(formAction, formData).then(function (response) {
            if (response.data) {
              var categorySection = winnerTrigger.closest('.finalists-category-section');
              var finalistsSelection = categorySection.querySelector('.js-finalists-selection');

              if (finalistsSelection) {
                finalistsSelection.disabled = true;
              }

              var currentEntryId = form.querySelector('[name="entryId"]').value;

              winnerTrigger.classList.remove('is-active');

              var inputSelectVictory = form.querySelector('[name="fields[selectVictory]"]');
              if (inputSelectVictory) {
                inputSelectVictory.value = 0;
              }

              var inputProvincialSubmitted = form.querySelector('[name="fields[provincialSubmitted]"]');
              if (inputProvincialSubmitted) {
                inputProvincialSubmitted.value = 0;
              }

              var newWinnerTrigger = form.querySelector('[data-winner-state="is-winner"]');
              newWinnerTrigger.classList.add('is-winner');
              newWinnerTrigger.classList.add('is-active');

              var cantBeWinnerTriggers = categorySection.querySelectorAll('[data-winner-state="cant-be-winner"]');
              if (cantBeWinnerTriggers.length) {
                cantBeWinnerTriggers.forEach(function (cantBeWinnerTrigger) {
                  var entryId = cantBeWinnerTrigger.closest('form').querySelector('[name="entryId"]').value;
                  if (entryId !== currentEntryId) {
                    cantBeWinnerTrigger.classList.add('is-active');
                  }
                });
              }

              var canBeWinnerTriggers = categorySection.querySelectorAll('[data-winner-state="can-be-winner"]');
              if (canBeWinnerTriggers.length) {
                canBeWinnerTriggers.forEach(function (canBeWinnerTrigger) {
                  canBeWinnerTrigger.classList.remove('is-active');
                });
              }
            }
          }).catch(function (error) {
            console.log(error);
          });
        });
      });
    }

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();