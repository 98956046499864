'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.ConsultationFilters = function ConsultationFilters() {
  /**
   * jQuery elements
   * @private
  */
  var filtersZone = document.querySelector('[data-consultation-filters]');

  /**
   * Has the class been initialized?
   * @private
  */
  var inited = false;

  var filterByRegion = function filterByRegion(e) {
    var categoryFilter = filtersZone.querySelector('[name="category"]');
    var schoolFilter = filtersZone.querySelector('[name="school"]');
    var yearFilter = filtersZone.querySelector('[name="year"]');

    if (categoryFilter) {
      categoryFilter.value = '';
    }

    if (schoolFilter) {
      schoolFilter.value = '';
    }

    axios.get('/actions/site-module/default/get-consultation-filters?region=' + e.target.value + '&category=' + categoryFilter.value + '&school=' + schoolFilter.value + '&year=' + yearFilter.value).then(function (response) {
      if (response.data.success) {
        filtersZone.innerHTML = response.data.html;

        var regionsFilter = filtersZone.querySelector('[name="region"]');

        if (regionsFilter) {
          regionsFilter.addEventListener('change', filterByRegion);
        }
      }
    }).catch(function (error) {
      console.log(error);
    });
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    if (filtersZone) {
      var regionsFilter = filtersZone.querySelector('[name="region"]');

      if (regionsFilter) {
        regionsFilter.addEventListener('change', filterByRegion);
      }
    }

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();