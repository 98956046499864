'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/* global site */

window.site = window.site || {};

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  var config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {}
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = options || {};

    if (options.env) {
      config.env = options.env;
    }
    if (options.csrf) {
      config.csrf = options.csrf;
    }
    if (options.csrfName) {
      config.csrfName = options.csrfName;
    }
    if (options.locale) {
      config.locale = options.locale;
    }
    if (options.device) {
      config.device = options.device;
    }
    if (options.preview) {
      config.preview = options.preview;
    }
    if (options.general) {
      config.general = options.general;
    }

    if (_typeof(site.AjaxForm) === 'object') {
      site.AjaxForm.init();
    }
    if (_typeof(site.CatForm) === 'object') {
      site.CatForm.init();
    }
    if (_typeof(site.Tables) === 'object') {
      site.Tables.init();
    }
    if (_typeof(site.Accordions) === 'object') {
      site.Accordions.init();
    }
    if (_typeof(site.Header) === 'object') {
      site.Header.init();
    }
    if (_typeof(site.CreateSchool) === 'object') {
      site.CreateSchool.init();
    }
    if (_typeof(site.Account) === 'object') {
      site.Account.init();
    }
    if (_typeof(site.FormDelete) === 'object') {
      site.FormDelete.init();
    }
    if (_typeof(site.NotationComponent) === 'object') {
      site.NotationComponent.init();
    }
    if (_typeof(site.SelectSchoolComponent) === 'object') {
      site.SelectSchoolComponent.init();
    }
    if (_typeof(site.CommentPopupComponent) === 'object') {
      site.CommentPopupComponent.init();
    }
    if (_typeof(site.ConsultationFilters) === 'object') {
      site.ConsultationFilters.init();
    }
    if (_typeof(site.Modals) === 'object') {
      site.Modals.init();
    }
    if (_typeof(site.SelectsTop3) === 'object') {
      site.SelectsTop3.init();
    }
    if (_typeof(site.SelectsFinalists) === 'object') {
      site.SelectsFinalists.init();
    }
    if (_typeof(site.SortableCategories) === 'object') {
      site.SortableCategories.init();
    }

    if ($(document).height() > $(window).height()) {
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll > 0) {
          $(".site-back-top").addClass('is-active');
        } else {
          $(".site-back-top").removeClass('is-active');
        }
      });
    }

    $(".site-back-top").on('click', function () {
      $(window).scrollTop(0, 'smooth');
    });

    console.table(options);

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    config: getConfig
  };
}();