'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Header = function Header() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $('.hero-account').click(function () {
      console.log('teuv');
      $(".sub-menu-account").toggleClass("is-active");
      $(".overlay-site").toggleClass("is-active");

      if ($(".sub-menu-account").hasClass("is-active")) {
        $(".sub-menu-account").removeClass("is-active");
        $(".navigation-mobile").removeClass("is-active");
        $(".overlay-site").removeClass("is-active");
      } else {
        $(".sub-menu-account").addClass("is-active");
        $(".navigation-mobile").addClass("is-active");
        $(".overlay-site").addClass("is-active");
      }
    });

    $(".overlay-site").click(function () {
      if ($(".overlay-site").hasClass("is-active")) {
        $(".sub-menu-account").removeClass("is-active");
        $(".navigation-mobile").removeClass("is-active");
        $(".sub-menu-infos").removeClass("is-active");
        $(".overlay-site").removeClass("is-active");
      };
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();