"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.MyAccount = function MyAccount() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  var count = 0;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var allInput = document.querySelectorAll(".form-input");
    var buttonSave = document.querySelectorAll(".saveAccount");

    if (allInput.length != 0 && buttonSave.length != 0) {
      allInput.forEach(function (input) {
        var svg = input.closest('.input-container').querySelector(".svg-pencil");
        input.addEventListener("change", function () {
          svg.style.display = "none";
          buttonSave[0].removeAttribute("disabled");
        });
        input.addEventListener("keyup", function () {
          svg.style.display = "none";
          buttonSave[0].removeAttribute("disabled");
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();