'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.CatForm = function CatForm() {

    'use strict';

    /**
     * jQuery elements
     * @private
     */

    /**
     * Has the class been initialized?
     * @private
     */

    var inited = false;

    var count = 0;

    /**
     * Initializes the class.
     * @public
     */
    var init = function init() {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        if ($('.form-dynamic').length > 0) {
            count = $('.form-dynamic').length;
        }

        if (count > 0) {
            $('.question-removing').removeClass('is-disabled');
            // $('.create-cat-btn').removeAttr('disabled');
        } else {
            $('.question-removing').addClass('is-disabled');
            // $('.create-cat-btn').prop('disabled', 'disabled');
        }

        $('.question-adding').click(function (e) {
            e.preventDefault();

            addquestion(count);
            count++;

            if (count > 0) {
                $('.question-removing').removeClass('is-disabled');
                // $('.create-cat-btn').removeAttr('disabled');
            } else {
                $('.question-removing').addClass('is-disabled');
                // $('.create-cat-btn').prop('disabled', 'disabled');
            }
        });

        $('.question-removing').click(function (e) {
            e.preventDefault();
            count--;
            removequestion();

            if (count > 0) {
                $('.question-removing').removeClass('is-disabled');
                // $('.create-cat-btn').removeAttr('disabled');
            } else {
                $('.question-removing').addClass('is-disabled');
                // $('.create-cat-btn').prop('disabled', 'disabled');
            }
        });

        $('.create-cat-btn').click(function (e) {
            if (count === 0) {
                var catForm = $('#main-form.create-cat-form');

                e.preventDefault();

                swal({
                    title: 'Au moins une question est requise pour créer une catégorie.',
                    icon: "warning"
                });
            } else {
                catForm.submit();
            }
        });

        $('.delete-cat-btn').click(function (e) {
            var catForm = $('.delete-cat-form');

            e.preventDefault();

            swal({
                title: 'Souhaitez-vous vraiment supprimer cette catégorie ?',
                icon: "warning",
                buttons: ['Retour', 'Confirmer']
            }).then(function (isConfirm) {
                catForm.submit();
            });
        });

        function addquestion(count) {
            $('.form-questions').append('<div class="form-dynamic"><input type="hidden" name="fields[form][new' + count + '][type]" value="59"><div class="col-xs-10"><label>Question</label><input class="form-input" type="text" required name="fields[form][new' + count + '][fields][label]" value=""></div><div class="col-xs-2"><label>Note Maximale</label><input class="form-input" type="number" required name="fields[form][new' + count + '][fields][noteMax]" value=""></div></div>');
        }

        function removequestion() {
            $('.form-dynamic').last().remove();
        }

        return true;
    };

    // Expose public methods & properties
    return {
        init: init
    };
}();