'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Accordions = function Accordions() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  var $accordions = $('[js-accordion]');

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $accordions.on('click', function (e) {
      e.preventDefault();

      var $ref = $(this).data('ref');
      console.log($ref);
      var $content = $(this).next('.accordion-content[data-ref="' + $ref + '"]');

      $(this).toggleClass('is-active');
      $content.toggleClass('is-active');
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();